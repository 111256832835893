import React from "react";
import { Layout } from "../components/Layout";

export default function () {
  return (
    <Layout title="Impressum" description="Impressum der Heidekojoten">
      <article className="prose md:prose-xl mx-3 md:mx-auto my-24">
        <h1>Impressum</h1>
        <h2>Heidekojoten’09 e.V. Soltau </h2>
        <p>Töpingen 33</p>
        <p>29633 Munster</p>
        <p>Telefon: +49 174 56 97 977</p>
        <p>Email: denis.krueger@heidekojoten.de</p>
        <h2>Vorstand</h2>
        <h3>Vorsitz</h3>
        <ul>
          <li>Denis Krüger</li>
          <li>Jan Pientka</li>
          <li>Töns Holste</li>
        </ul>
        <h3>Beisitz</h3>
        <ul>
          <li>Kristina Kutter</li>
          <li>Karsten Bertram</li>
        </ul>
        <h2>Eingetragen in</h2>
        <p>Vereinsregister Soltau</p>
        <p>Gläubiger-ID: DE85ZZZ00001110253</p>
      </article>
    </Layout>
  );
}
